import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
//import Gallery from "../components/gallery"
import Lightbox from "../components/lightbox"

const RoomPage = ({ data }) => (
  <Layout>
    <SEO title="Rooms, Location and Amenities" />
    <h2>Rooms</h2>
    <div>
    <Lightbox images={data.allImageSharp.edges} />
    </div>
    <div>
      <br/>
      <h3>Amenities</h3>
      <div class="colcon">
        <ul>
          <li>Elevator</li>
          <li>Air Conditioning(AC)</li>
          <li>TV with Cable</li>
          <li>King Sized Bed</li>
          <li>CCTV Cameras</li>
          <li>Power Backup</li>
          <li>Fan</li>
          <li>Geyser</li>
          <li>Free Wifi</li>
          <li>Complimentary Breakfast</li>
        </ul>
      </div>
    </div>
    <div>
      <br/>
      <h3>Getting to GuruKripa</h3>
      <div class="map-responsive">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.108881412693!2d88.47304121523293!3d22.575030685181623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02752dc6b056bb%3A0x261a80569cd37b9d!2sOYO%204102%20Guru%20Kripa!5e0!3m2!1sen!2sus!4v1574033949345!5m2!1sen!2sus" width="900" height="450" title="gk_map"></iframe>
      </div>
    </div>
  </Layout>
)

RoomPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RoomPage

export const pageQuery = graphql`
  query RoomQuery {
    allImageSharp {
      edges {
        node {
          sizes(maxWidth: 1800) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  }
  `
